body {
  background: linear-gradient(#0ff, #9600ff);
  margin: 0;
}

#wrap {
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Gemunu Libre, sans-serif;
  display: flex;
}

#ohjelma p {
  border-left: 3px solid #ff69b4;
  padding-left: 3px;
}

a, a:visited {
  color: purple;
  text-decoration: none;
}

#timer {
  font-size: 4rem;
}

#nav {
  padding-top: 2rem;
}

#versus-wrap {
  width: 100%;
  background: #000;
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.slot {
  width: 400px;
  height: 600px;
  opacity: 1;
  background-color: #dcdcdc;
  margin: 20px;
  display: flex;
}

.slot-child {
  width: 100%;
  height: 100%;
  background-color: #000;
  justify-content: end;
  transition: all .3s ease-in-out;
  display: flex;
  overflow: hidden;
  transform: translateY(-100vh);
}

.tribe {
  height: -moz-fit-content;
  height: fit-content;
  color: #fff;
  margin: auto auto 0;
  font-size: 3rem;
  font-weight: 700;
}

.slot-child__visible {
  transform: translateY(0%);
}

#versus-h1 {
  font-size: 4rem;
}

/*# sourceMappingURL=index.6976e945.css.map */
